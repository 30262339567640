export default {
    bodyBackground: "#FFFFFF",
    lightBackground: "#EFF7E5",
    darkBackground: "#13AC8B",
    darkOrange: "#FF9933",
    tableDarkHeader: "#13AC8B",
    tableHeader: "#4DC26D",
    tableDataHeader: "#EFF7E5",
    tableDataRow: "#FFFFFF",
    tableFooter: "#13AC8B",
    tableRowHoverColor: "#DCEDC8",
    tableRowSelectColor: "#FFE5A8",
    pageDetailBarColor: "#4DC26D",
    versionHeaderBarColor: "#13AC8B",
    textPrimary: "#000000",
    textSecondary: "#FFFFFF",
    instanceInfoBox: "#EFF7E5",
    metadataInfoBox: "#DCEDC8",
    buttonDark: "#13AC8B",
    buttonPrimary: "#9CE142",
    activeTabColor: "#9CE142",
    buttonSecondary: "#FFE5A8",
    plotBarColor: "#FF9933",
    disabledColor: "#DCDCDC",
    subTabColor: "#DCEDC8",
    lightText: "#FFFFFF",
    darkText: "#000000",
    darkGreenText: "#13AC8B"
};